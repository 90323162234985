import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "29",
  height: "30",
  fill: "none",
  viewBox: "0 0 29 30"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "m11 18.68-5-5 1.41-1.41L11 15.85l7.59-7.59L20 9.68z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#4AB5BD",
      "fill-rule": "evenodd",
      d: "M14.5 29.094c8.008 0 14.5-6.492 14.5-14.5S22.508.094 14.5.094 0 6.586 0 14.594s6.492 14.5 14.5 14.5",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "m13 19.514-5-5 1.41-1.41 3.59 3.58 7.59-7.59 1.41 1.42z"
    }, null, -1)
  ])))
}
export default { render: render }