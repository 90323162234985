<template>
  <div>
    <transition mode="out-in" name="sf-collapse-top">
      <div
        v-if="notifications.length > 0"
        class="bg-[#ebf1cb] py-3"
        :class="typeClasses"
        data-test-id="notifications__container"
      >
        <div
          class="flex max-w-screen-xl m-auto px-4 justify-between align-center"
        >
          <div
            v-if="firstNotification.htmlContent"
            v-html="firstNotification.htmlContent"
          ></div>
          <template v-else>
            <div class="flex gap-4">
              <component
                :is="icons[firstNotification.type] || 'div'"
                v-if="firstNotification.showIcon"
                :height="24"
                :width="24"
                :class="iconClasses"
              />

              <div>{{ firstNotification.message }}</div>
            </div>
          </template>

          <div class="flex align-center">
            <button @click="handleCloseNotification">
              <IconXLg class="w-5" />
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { IconExclamationTriangleFill } from '#components'
import IconExclamationOctagonFill from '~/components/icons/IconExclamationOctagonFill.vue'

import InfoCircleFill from '~/public/icons/info-circle-fill.svg?component'
import CheckIcon from '~/public/icons/check-icon.svg?component'
const { notifications, handleCloseNotification } = useNotifications()

const firstNotification = computed(() => notifications.value[0])

const typeClasses = computed(() => ({
  'bg-teal-50 text-teal-600': firstNotification.value.type === 'INFO',
  'bg-danger-light text-gray-700': firstNotification.value.type === 'ERROR',
  'bg-success-light text-gray-700': firstNotification.value.type === 'SUCCESS',
  'bg-warning-light text-gray-700 ': firstNotification.value.type === 'WARNING',
}))

const icons = {
  INFO: InfoCircleFill,
  ERROR: IconExclamationTriangleFill,
  SUCCESS: CheckIcon,
  WARNING: IconExclamationOctagonFill,
}

const iconClasses = computed(() => ({
  'text-success': firstNotification.value.type === 'INFO',
  'bg-danger-light text-danger': firstNotification.value.type === 'ERROR',
  '': firstNotification.value.type === 'SUCCESS',
  'bg-warning-light text-yellow-500':
    firstNotification.value.type === 'WARNING',
}))
</script>

<style scoped>
:deep(a) {
  text-decoration: underline;
  font-weight: 500;
}
</style>
